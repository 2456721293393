<template>
  <div>
    <div class="table-responsive">
      <div class="relative overflow-x-auto">
        <div class="text-sm flex-col text-center">
          <table class="table-auto w-full text-sm text-left text-gray-500 dark:text-white">
            <thead class="text-xs uppercase bg-gray-200 dark:bg-gray-800 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-2 py-3">No</th>
                <th scope="col" class="px-2 py-3">Purchase Date</th>
                <th scope="col" class="px-2 py-3">Payment ID</th>
                <th scope="col" class="px-2 py-3">Product ID</th>
                <th scope="col" class="px-2 py-3">Original Trx</th>
                <th scope="col" class="px-2 py-3">Transaction ID</th>
                <th scope="col" class="px-2 py-3">Receipt Version</th>

              </tr>
            </thead>
            <tbody>

              <tr v-for="(orderAppleAps, index) in orderdetail?.paymentappleiaps" 
                :key="orderAppleAps?.id"
                class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td class="py-2 px-2 text-gray-500">
                  {{ index + 1 }}
                </td>
                <td class="py-2 px-2 text-gray-500">
                  <span>{{ formatDate(orderAppleAps?.purchase_date).date }}</span>
                  <br>
                  <span class="text-xs">{{ formatDate(orderAppleAps?.purchase_date).time }}</span>
                </td>
                <td class="py-2 px-2 text-gray-500">
                 {{ orderAppleAps?.payment_id || '-' }}
                </td>
                <td class="py-2 px-2 text-gray-500">
                 {{ orderAppleAps?.product_id || '-' }}
                </td>
                <td class="py-2 px-2 text-gray-500">
                 {{ orderAppleAps?.original_transaction_id || '-' }}
                </td>
                <td class="py-2 px-2 text-gray-500">
                 {{ orderAppleAps?.transaction_id || '-' }}
                </td>
                <td class="py-2 px-2 text-gray-500">
                 {{ orderAppleAps?.receipt_version || '-' }}
                </td>
            </tr>

            </tbody>
            <tbody v-if="orderdetail.paymentappleiaps.length === 0">
              <td colspan="15">
                <p class="text-sm text-gray-500 text-center mt-2">There are no records to show</p>
            </td>
            </tbody>
          </table>
          
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DetailItunes',
  props: {
    orderdetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      header: [
        { key: 'No', label: 'No' },
        { key: 'purchase_date', label: 'Purchase Date' },
        { key: 'payment_id', label: 'Payment ID' },
        { key: 'product_id', label: 'Product ID' },
        { key: 'original_trx', label: 'Original Trx' },
        { key: 'id_trx', label: 'Transaction ID' },
        { key: 'receipt_version', label: 'Receipt Version' },
      ],
    };
  },
  methods: {
    formatDate(tgl) {
      return {
        date: moment(tgl).format('DD MMMM YYYY'),
        time: moment(tgl).format('HH:mm:ss'),
      };
    },
  },
};
</script>
