<template>
  <div class="text-black ">
    <span
      v-for="status in filteredStatus"
      :key="status.id"
      :class="`${status.class}`"
      class="capitalize "
    >
      {{ status.text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "PaymentStatus",
  props: {
    payment: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      paymentStatuses: [
        { id: 20001, text: "Waiting for Payment", class: "bg-yellow-100 text-yellow-900 rounded-lg px-2 py-1" },
        { id: 20002, text: "Payment Process", class: "bg-yellow-100 text-yellow-900 rounded-lg px-2 py-1" },
        { id: 20003, text: "Payment Billed", class: "bg-yellow-100 text-yellow-900 rounded-lg px-2 py-1" },
        { id: 20004, text: "Payment Restored", class: "bg-purple-100 text-purple-900 rounded-lg px-2 py-1" },
        { id: 50000, text: "Payment Cancelled", class: "bg-red-100 text-red-900 rounded-lg px-2 py-1" },
        { id: 50001, text: "Payment Error", class: "bg-red-100 text-red-900 rounded-lg px-2 py-1" },
        { id: 50002, text: "Delivery Error", class: "bg-red-100 text-red-900 rounded-lg px-2 py-1" },
        { id: 50003, text: "Payment Denied", class: "bg-red-100 text-red-900 rounded-lg px-2 py-1" },
        { id: 50004, text: "Expired", class: "bg-gray-200 text-gray-900 rounded-lg px-2 py-1" },
        { id: 10001, text: "New Order", class: "bg-blue-100 text-blue-900 rounded-lg px-2 py-1" },
      ],
    };
  },
  computed: {
    filteredStatus() {
      return this.paymentStatuses.filter((status) => status.id === this.payment);
    },
  },
};
</script>

<style scoped>
.badge {
  @apply px-3 py-1 rounded-lg font-semibold;
}
</style>
