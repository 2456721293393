<template>
  <div>
    <div class="relative overflow-x-auto">
      <div class="text-sm flex-col text-center">
        <table class="table-auto w-full text-sm text-left text-gray-500 dark:text-white">
          <thead class="text-xs uppercase bg-gray-200 dark:bg-gray-800 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-2 py-3">No</th>
              <th scope="col" class="px-2 py-3">Payment Time</th>
              <th scope="col" class="px-2 py-3">Status</th>
              <th scope="col" class="px-2 py-3">Purchase Token</th>
              <th scope="col" class="px-2 py-3">IAB Order ID</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(orderpayment, index) in orderdetail.paymentinappbillings" :key="orderpayment.id"
              class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
              <td class="py-2 px-2 text-gray-500">
                {{ index + 1 }}
              </td>
              <td class="py-2 px-2 text-gray-500">
                {{ formatDate(orderpayment.created) }}
              </td>
              <td class="py-2 px-2 text-gray-500">
                {{ orderpayment.status }}
              </td>
              <td class="py-2 px-2 text-gray-500 w-44" >
                {{
            orderpayment?.purchase_token
              ? orderpayment?.purchase_token
              : 'PurchaseToken Notfound'
          }}
          <button
            v-if="orderpayment?.purchase_token"
            size="sm"
            variant="outline"
            @click="copyTextToClipboard(orderpayment.purchase_token)"
          >
            <i class="fa fa-copy"></i>
        </button>
        <b-button
            size="sm"
            variant="primary"
            class="ml-2"
            v-b-modal.modal-insert-purchase-token-g-payment
            v-if="!showButtonInsertToken"
          >
            Insert Token
          </b-button>
              </td>
              <td class="py-2 px-2 text-gray-500">
                {{
            orderpayment?.iab_order_id
              ? orderpayment?.iab_order_id
              : 'GPA Order Not Found'
          }}
          <b-button
            size="sm"
            variant="primary"
             class="ml-2"
            v-b-modal.modal-insert-gpa
            v-if="!showButtonInsertGPA"
          >
            Insert GPA
          </b-button>
              </td>
            </tr>
           
          </tbody>
          <tbody v-if="orderdetail.paymentinappbillings.length === 0">
              <td colspan="15">
                <p class="text-sm text-gray-500 text-center mt-2">There are no records to show</p>
            </td>
            </tbody>
        </table>


      </div>

    </div>
  
    <!-- ======= modal insert purchase token for google payment -->
    <b-modal
      id="modal-insert-purchase-token-g-payment"
      :title="'Insert Token for order : ' + orderdetail.order_id"
      :ok-visible="true"
    >
      <b-form id="form-insert-token-g-payment" @submit.prevent="onSubmitToken">
        <b-form-group>
          <b-form-input
            v-model="purchase_token"
            id="token-g-payment"
            placeholder="put token here.."
            required
          >
          </b-form-input>
          <br />
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            form="form-insert-token-g-payment"
            type="submit"
            >Save</b-button
          >
        </div>
      </template>
    </b-modal>

    <!-- ======= modal insert GPA for google payment -->
    <b-modal
      id="modal-insert-gpa"
      :title="'Insert GPA for order : ' + orderdetail?.order_id"
      :ok-visible="true"
    >
      <b-form id="form-insert-gpa" @submit.prevent="onSubmitGPA">
        <b-form-group>
          <b-form-input
            v-model="google_iab"
            placeholder="GPA.3328-1533-0108-86973"
            required
          >
          </b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" form="form-insert-gpa" type="submit"
            >Save</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DetailGoogle',
  props: {
    orderdetail: {
      type: Object,
      required: true,
    },
    submitToken: {
      type: Function,
      required: true,
    },
    submitGPA: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      purchase_token: '',
      google_iab: null,
      header: [
        { key: 'No', label: 'No' },
        { key: 'created', label: 'Payment Time' },
        { key: 'status', label: 'Status' },
        { key: 'purchase_token', label: 'Purchase Token' },
        { key: 'iab', label: 'IAB Order ID' },
      ],
      showButtonInsertToken: false,
      showButtonInsertGPA: false,
    };
  },
  watch: {
    orderdetail: function() {
      this.showButtonInsertToken = this.orderdetail.paymentinappbillings.some(
        (e) => e.purchase_token,
      );
      this.showButtonInsertGPA = this.orderdetail.paymentinappbillings.some(
        (e) => e.iab_order_id,
      );
    },
  },
  created: function() {
    this.showButtonInsertToken = this.orderdetail.paymentinappbillings.some(
      (e) => e.purchase_token,
    );
    this.showButtonInsertGPA = this.orderdetail.paymentinappbillings.some(
      (e) => e.iab_order_id,
    );
  },
  methods: {
    formatDate(tgl) {
      return moment(tgl).format('DD MMMM YYYY HH:mm:ss');
    },
    onSubmitToken() {
      this.submitToken({
        purchase_token: this.purchase_token,
        order_id: this.orderdetail?.order_id,
      });
      this.$bvModal.hide('modal-insert-purchase-token-g-payment');
      this.purchase_token = '';
    },
    onSubmitGPA() {
      this.submitGPA({
        google_iab: this.google_iab,
        order_id: this.orderdetail?.order_id,
      });
      this.$bvModal.hide('modal-insert-gpa');
      this.google_iab = null;
    },
  },
};
</script>
