<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="bg-white p-4 my-4 rounded-lg shadow-sm">
      <label> <span @click='cancel()' class="text-capitalize cursor-pointer text-gray-900 font-semibold">
          Discount</span> <i class="fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"></i> <span
          class="text-gray-500 font-normal capitalize"> {{ item.id }}</span>
      </label>
      <h4 class="text-xl capitalize"> {{ item.id }}</h4>
    </div>
    <div class="bg-white p-4 my-4 rounded-lg shadow-sm">
      <label class="text-base text-gray-900 font-bold w-full block">Order Details</label>
      <div class="flex justify-start">
        <div class="w-1/2 mt-2">
          <div class="flex flex-col ">
            <label class="font-semibold text-sm mb-1">Order ID</label>
            <span class="text-gray-500">{{ item.id }}</span>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Email</label>
            <router-link :to="{
    path: '/customers/detail/' + item?.users?.email,
  }" class="text-gray-500 no-underline hover:no-underline hover:bg-transparent" target="_blank">
              {{ item.users ? item.users.email : '-' }}
            </router-link>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Order Number</label>
            <span class="text-gray-500">{{ item.order_number }}</span>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Renewal Type</label>
            <span v-if="item.is_renewal" class="text-gray-500">
              Renewal
            </span>
            <span v-else class="text-gray-500">
              Non-Renewal
            </span>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Total Amount</label>
            <span class="text-gray-500">
              {{ item.currency_code?.toUpperCase() }}
              {{ formatPrice(item.total_amount, item.currency_code) }}
            </span>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Discount</label>
            <span class="text-gray-500">
              {{ item.currency_code?.toUpperCase() }}
              {{
    getDiscountPrice(
      item.total_amount,
      item.final_amount,
      item.currency_code,
    )
  }}
            </span>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Final Amount</label>
            <span class="text-gray-500">
              {{ item.currency_code?.toUpperCase() }}
              {{ formatPrice(item.final_amount, item.currency_code) }}
            </span>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Store Referral Code</label>
            <span class="text-gray-500">{{ item.store_code?.store || '-' }}</span>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Payment Type</label>
            <span class="text-gray-500"> {{ item.payment?.name || '-' }}</span>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Platform Type</label>
            <div class="text-gray-500 flex justify-start">
              <Platform :platform="item.platform_id" />
            </div>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Client ID</label>
            <span class="text-gray-500 normal-case"> {{ clientID(item.client_id) }}</span>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Tier Code</label>
            <span class="text-gray-500 normal-case"> {{ item.tier_code ? item.tier_code : '-' }}</span>
          </div>
        </div>
        <div class="w-1/2">
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Status</label>
            <div>
              <div v-if="item.is_active">
                <span class="bg-blue-100 text-blue-800 px-2 py-1 rounded-lg text-sm">active</span>
              </div>
              <div v-else>
                <span class="bg-red-100 text-red-800 px-2 py-1">
                  inactive</span>
              </div>
            </div>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Order Status</label>
            <div class="text-gray-500 flex justify-start">
              <paymentview :payment="item.order_status" />
            </div>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Transaction Time</label>
            <span class="text-gray-500">
              {{ formatDate(item.created) }}
            </span>
          </div>
          <div class="flex flex-col mt-3">
            <label class="font-semibold text-sm mb-1">Last Modified Date</label>
            <span class="text-gray-500">
              {{ formatDate(item.modified) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white p-4 my-4 rounded-lg shadow-sm">
      <label class="font-bold text-base">All Contents Under this Section Title</label>
      <div class="relative overflow-x-auto">
        <div class="text-sm flex-col text-center">
          <table class="table-auto w-full text-sm text-left text-gray-500 dark:text-white">
            <thead class="text-xs uppercase bg-gray-200 dark:bg-gray-800 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-2 py-3">Orderline key</th>
                <th scope="col" class="px-2 py-3">Item name</th>
                <th scope="col" class="px-2 py-3">brand name</th>
                <th scope="col" class="px-2 py-3">Qty</th>
                <th scope="col" class="px-2 py-3">Price</th>
                <th scope="col" class="px-2 py-3">Discount</th>
                <th scope="col" class="px-2 py-3">Final price</th>

              </tr>
            </thead>
            <tbody>

              <tr v-for="orderline in item.orderlines" :key="orderline.id"
              
                class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td class="px-2 py-4 font-medium text-gray-900 dark:text-white">
                  {{ orderline.id }}
                </td>
                <td class="px-2 py-4">
                  {{ orderline.name }}
                </td>
                <td class="px-2 py-4">
                  {{ orderline.brand_name }}
                </td>
                <td class="px-2 py-4">
                  {{ orderline.quantity }}
                </td>
                <td class="px-2 py-4">
                  {{ item?.currency_code }}
                  {{ formatPrice(orderline.price, orderline.currency_code) }}
                </td>
                <td class="px-2 py-4">
                  {{ item?.currency_code }}
                  {{
    getDiscountPrice(
      orderline.price,
      orderline.final_price,
      item?.currency_code,
      orderline.currency_code,
      orderline.quantity
    )
  }}
                </td>
                <td class="px-2 py-4">
                  {{ item?.currency_code }}
                  {{
    formatPrice(orderline.final_price, orderline.currency_code)
  }}
                </td>
              </tr>
              
            </tbody>
            <tbody v-if="item.orderlines.length === 0">
              <td colspan="15">
                <p class="text-sm text-gray-500 text-center mt-2">There are no records to show</p>
            </td>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <div class="bg-white p-4 my-4 rounded-lg shadow-sm">
      <label class="font-bold text-base">Payment Details</label>
      <div class="flex items-start justify-between mb-4">
        <div class="w-8/12">
          <div v-if="detailPayment">
            <detailPaymentVue :orderdetail="detailPayment" :updatePaymentType="onUpdatePaymentType" />
          </div>
        </div>
        <div>
          <div v-if="item.platform_id === 1" class=" mb-3">
            <button class="bg-blue-500 text-white rounded-lg px-2 py-2" @click="popupAppleID"
              v-if="[20001, 50004, 20002, 10001].includes(item.order_status)">
              Insert Apple Order ID
            </button>
          </div>
        </div>

      </div>
      <div class="relative overflow-x-auto">
        <div>
          <div v-if="this.item.paymentgateway_id == applePay &&
    detailPayment.paymentappleiaps
    ">
            <Itunes :orderdetail="detailPayment" />
          </div>
          <div v-if="this.item.paymentgateway_id == googlePay &&
    detailPayment.paymentinappbillings
    ">
            <Google :orderdetail="detailPayment" :submitToken="onSubmitToken" :submitGPA="onSubmitGPA" />
          </div>
          <div v-if="this.item.paymentgateway_id == linkAjaPay &&
    (detailPayment.paymentva || detailPayment.paymentdetail)
    ">
            <LinkAja :orderdetail="detailPayment" />
          </div>
          <div v-if="paymentVA.includes(parseInt(this.item.paymentgateway_id)) &&
    (detailPayment.paymentva || detailPayment.paymentdetail)
    ">
            <VirtualAccount :orderdetail="detailPayment" />
          </div>
          <div v-if="this.item.paymentgateway_id == gopay &&
    detailPayment.paymentgopay
    ">
            <GoPay :orderdetail="detailPayment" />
          </div>
          <div v-if="this.item.paymentgateway_id == cc && detailPayment.paymentcc
    ">
            <CreditCard :orderdetail="detailPayment" />
          </div>
          <div v-if="this.item.paymentgateway_id == free &&
    detailPayment.orderlinediscount
    ">
            <FreePurchase :orderdetail="detailPayment" />
          </div>
          <div v-if="eTwoPayCIMB.includes(
    parseInt(this.item.paymentgateway_id),
  ) && detailPayment.paymentdetail
    ">
            <eTwoPayGroup :orderdetail="detailPayment" />
          </div>
          <!-- <div v-if="this.item.paymentgateway_id == scoopWallet && detailPayment
    ">
            <ScoopWallet :orderdetail="detailPayment" />
          </div> -->
        </div>

      </div>
    </div>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import paymentview from '../../components/payments/payment.vue';
import Platform from '../../components/platform/platform.vue';
import Itunes from '../../components/orders/detail-itunes.vue';
import Google from '../../components/orders/detail-google.vue';
import LinkAja from '../../components/orders/detail-linkaja.vue';
import VirtualAccount from '../../components/orders/detail-virtualaccount.vue';
import GoPay from '../../components/orders/detail-gopay.vue';
import CreditCard from '../../components/orders/detail-creditcard.vue';
import FreePurchase from '../../components/orders/detail-freepurchase.vue';
import eTwoPayGroup from '../../components/orders/detail-cimbgroup.vue';
//import ScoopWallet from '../../components/orders/detail-scoop-wallet.vue';
import detailPaymentVue from '../../components/payments/detail-payment.vue';
import moment from 'moment';
import { EventBus } from '../../../src/event-bus'

import constant from '../../store/constant';
const paymentVA = [
  constant.PAYMENT_GATEWAY['Virtual Account BNI'],
  constant.PAYMENT_GATEWAY['Virtual Account Permata'],
  constant.PAYMENT_GATEWAY['Virtual Account BCA'],
  constant.PAYMENT_GATEWAY['Virtual Account Mandiri'],
  constant.PAYMENT_GATEWAY['Qris Payment'],
];
const eTwoPayCIMB = [
  constant.PAYMENT_GATEWAY['CIMB Clicks'],
  constant.PAYMENT_GATEWAY['CIMB GoMobile'],
  constant.PAYMENT_GATEWAY['CIMB RekPon'],
];
export default {
  name: 'OrderDetail',
  components: {
    paymentview,
    Platform,
    Itunes,
    
    Google,
    LinkAja,
    VirtualAccount,
    GoPay,
    CreditCard,
    FreePurchase,
    eTwoPayGroup,
    //ScoopWallet,
    detailPaymentVue,
  },
  data() {
    return {
      paymentVA,
      eTwoPayCIMB,
      googlePay: constant.PAYMENT_GATEWAY['Google In App Billing'],
      applePay: constant.PAYMENT_GATEWAY['Apple iTunes'],
      linkAjaPay: constant.PAYMENT_GATEWAY['Link Aja'],
      gopay: constant.PAYMENT_GATEWAY['Go Pay'],
      cc: constant.PAYMENT_GATEWAY['Credit Card'],
      free: constant.PAYMENT_GATEWAY['Free Purchase'],
      scoopWallet: constant.PAYMENT_GATEWAY['SCOOP Wallet'],
      headOrderline: [
        { key: 'No', label: 'No' },
        { key: 'id', label: 'Orderline ID' },
        { key: 'item_name', label: 'Item Name' },
        { key: 'brand_name', label: 'Brand Name' },

        { key: 'qty', label: 'Qty' },
        { key: 'price', label: 'Price' },
        { key: 'discount', label: 'Discount' },
        { key: 'final_price', label: 'Final Price' },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Detail Order | Content Management System Ebooks Gramedia.com';
      },
    },

    isErrorOne: function () {
      if (!this.isErrorOne) return;
      this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
    },
    item: function () {
      if (!this.item) return;

      this.getDetailPayment({
        orderID: this.item.id,
        paymentgateway: this.item.paymentgateway_id,
      });
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.orders.isLoading,
      item: (state) => state.orders.item,
      detailPayment: (state) => state.payments.item,
      isErrorOne: (state) => state.orders.isErrorOne,
    }),
  },
  created: function () {
    this.fetchPage();
  },

  methods: {
    ...mapActions('orders', [
      'fetchOrdersById',
      'postOrderManuaAppleId',
      'updatePurchaseToken',
      'updateGPA',
    ]),
    ...mapActions('payments', ['getDetailPayment', 'updatePaymentType']),

    fetchPage() {
      let query = {};
      if (this.$route.query.orderid) {
        query.orderid = this.$route.query.orderid;
      }
      if (this.$route.query.ordernumber) {
        query.ordernumber = this.$route.query.ordernumber;
      }
      this.fetchOrdersById(query);
    },
    cancel() {
      this.$router.push('/orders');
    },
    popupAppleID() {
      this.$swal.fire({
        title: 'Add Invoice Order ID',
        html:
          '<input id="order_id" type="number" disabled class="form-control mb-4" placeholder="Order id" value="' + this.item.id + '">' +
          '<input id="invoice_id"  class="form-control" placeholder="Invoice ID">',
        focusConfirm: false,
        confirmButtonText: 'Submit',
        showCancelButton: true,
        preConfirm: () => {
          const order_id = document.querySelector('#order_id').value;
          const invoice_id = document.querySelector('#invoice_id').value;

          if (!order_id || !invoice_id) {
            this.$swal.showValidationMessage('Please enter both order ID and invoice ID');
            return false;
          }

          const payload = { order_id, invoice_id };
          return payload;
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.postOrderManuaAppleId(result)
              .then((response) => {
                if (response.status == 200) {
                  this.$swal('Success', 'Successfully updated', 'success');
                  this.fetchPage();
                } else {
                  const errorString = response?.data?.error;
                  const match = errorString.match(/state: (\d+)/);
                  const errorCode = match ? match[1] : null;
                  if (errorCode === '90000') {
                    this.$swal('Process Failed', `The data is already in PAID status.`, 'error');
                  } else {
                    this.$swal('Process Failed', `Process Failed. Please check the Apple Order ID you entered`, 'error');
                  }
                }
              })
              .catch((err) => {
                console.log(err)
              })
          }
        });
    },
    formatPrice(value, currencyCode) {
      if (currencyCode?.toLowerCase() == 'idr') {
        let val = (value / 1).toFixed(0).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return value;
    },
    clientID(client_id) {
      let clientName = '-';
      for (let [key, value] of Object.entries(constant.CLIENT_ID)) {
        if (parseInt(client_id) == value) {
          clientName = key;
        }
      }
      return clientName.replace('_', ' ');
    },
    formatDate(tgl) {
      return moment(tgl).format('DD MMMM YYYY HH:mm:ss');
    },
    getDiscountPrice(totalAmount, finalAmount, currency) {
      if (!isNaN(totalAmount) && !isNaN(finalAmount)) {
        const discount = parseFloat(totalAmount) - parseFloat(finalAmount);
        return this.formatPrice(parseFloat(discount), currency);
      }
      return '-';
    },
    getDiscountPricePerItem(totalAmount, finalAmount, currency, qty) {
      if (!isNaN(totalAmount) && !isNaN(finalAmount)) {
        const discount =
          parseFloat(totalAmount) * parseInt(qty) - parseFloat(finalAmount);
        return this.formatPrice(parseFloat(discount), currency);
      }
      return '-';
    },
    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
    async onSubmitToken({ purchase_token, order_id }) {
      this.updatePurchaseToken({
        purchase_token,
        order_id,
      })
        .then((response) => {
          if (response.status == 200) {
            this.$swal('Success', 'token successfully updated', 'success');
          } else {
            this.$swal('Process Failed', `${response?.data?.message}`, 'error');
          }
        })
        .catch(() => {
          const message =
            'Opps.. Something is wrong,please contact your administrator';
          this.$swal('Process Failed', message, 'error');
        })
        .finally(() => {
          this.fetchPage();
        });
    },

    async onSubmitGPA({ google_iab, order_id }) {
      this.updateGPA({
        google_iab,
        order_id,
      })
        .then((response) => {
          if (response.status == 200) {
            EventBus.$emit('show-toast', {
           message: 'GPA successfully updated',
           type: 'Success',
      });
          } else {
            this.$swal('Process Failed', `${response?.data?.message}`, 'error');
            EventBus.$emit('show-toast', {
           message:`${response?.data?.message}`,
           type: 'Process Failed',
      });
          }
        })
        .catch(() => {
          const message =
            'Opps.. Something is wrong,please contact your administrator';
          this.$swal('Process Failed', message, 'error');
        })
        .finally(() => {
          this.fetchPage();
        });
    },

    onUpdatePaymentType(type, id) {
      this.updatePaymentType({ type, id })
        .then((res) => {
          if (res?.status == 200) {
            EventBus.$emit('show-toast', {
           message: 'payment type successfully updated',
           type: 'Success',
      });
          } else {
            if (res?.status == 403) {
              EventBus.$emit('show-toast', {
               message: `${res?.data?.message}`,
              type: 'Process Failed',
              });
              
              return;
            } else {
              EventBus.$emit('show-toast', {
               message: `${res?.data?.message}`,
              type: 'Process Failed',
              });
            }
          }
        })
        .catch(() => {
          const message =
            'Opps.. Something is wrong,please contact your administrator';
          this.$swal('Process Failed', message, 'error');
        })
        .finally(() => {
          this.fetchPage();
        });
    },
  },
};
</script>
