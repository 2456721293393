var render = function () {
  var _vm$orderdetail, _vm$orderdetail2, _Object$keys, _vm$orderdetail3, _vm$orderdetail6, _vm$orderdetail9, _vm$orderdetail10, _vm$orderdetail11, _vm$orderdetail12, _vm$orderdetail13, _vm$orderdetail14, _vm$orderdetail15, _vm$orderdetail16, _vm$orderdetail17, _vm$orderdetail18, _vm$orderdetail19, _vm$orderdetail20, _vm$orderdetail21;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('div', {
    staticClass: "w-1/2"
  }, [_c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Payment ID")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" " + _vm._s((_vm$orderdetail = _vm.orderdetail) !== null && _vm$orderdetail !== void 0 && _vm$orderdetail.id ? (_vm$orderdetail2 = _vm.orderdetail) === null || _vm$orderdetail2 === void 0 ? void 0 : _vm$orderdetail2.id : '-') + " ")])]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Payment Type")]), (_Object$keys = Object.keys(_vm.orderdetail)) !== null && _Object$keys !== void 0 && _Object$keys.length ? _c('div', [(_vm$orderdetail3 = _vm.orderdetail) !== null && _vm$orderdetail3 !== void 0 && _vm$orderdetail3.is_test_payment ? _c('span', {
    staticClass: "text-gray-500 text-base bg-gray-100 rounded-lg",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "data-toggle": "tooltip",
      "data-placement": "top",
      "title": "Update Payment Type To Production"
    },
    on: {
      "click": function ($event) {
        var _vm$orderdetail4, _vm$orderdetail5;
        return _vm.editPaymentType((_vm$orderdetail4 = _vm.orderdetail) === null || _vm$orderdetail4 === void 0 ? void 0 : _vm$orderdetail4.is_test_payment, (_vm$orderdetail5 = _vm.orderdetail) === null || _vm$orderdetail5 === void 0 ? void 0 : _vm$orderdetail5.id);
      }
    }
  }, [_vm._v("TESTING")]) : _vm._e(), !((_vm$orderdetail6 = _vm.orderdetail) !== null && _vm$orderdetail6 !== void 0 && _vm$orderdetail6.is_test_payment) ? _c('span', {
    staticClass: "text-white bg-blue-500 text-base px-2 py-1 rounded-lg",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "data-toggle": "tooltip",
      "data-placement": "top",
      "title": "Update Payment Type To Testing"
    },
    on: {
      "click": function ($event) {
        var _vm$orderdetail7, _vm$orderdetail8;
        return _vm.editPaymentType((_vm$orderdetail7 = _vm.orderdetail) === null || _vm$orderdetail7 === void 0 ? void 0 : _vm$orderdetail7.is_test_payment, (_vm$orderdetail8 = _vm.orderdetail) === null || _vm$orderdetail8 === void 0 ? void 0 : _vm$orderdetail8.id);
      }
    }
  }, [_vm._v("Production")]) : _vm._e()]) : _c('div', [_vm._v(" - ")])]), [24].includes((_vm$orderdetail9 = _vm.orderdetail) === null || _vm$orderdetail9 === void 0 ? void 0 : _vm$orderdetail9.paymentgateway_id) ? _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Currency")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" " + _vm._s((_vm$orderdetail10 = _vm.orderdetail) !== null && _vm$orderdetail10 !== void 0 && _vm$orderdetail10.currency_code ? (_vm$orderdetail11 = _vm.orderdetail) === null || _vm$orderdetail11 === void 0 ? void 0 : _vm$orderdetail11.currency_code : '-') + " ")])]) : _vm._e(), [24].includes((_vm$orderdetail12 = _vm.orderdetail) === null || _vm$orderdetail12 === void 0 ? void 0 : _vm$orderdetail12.paymentgateway_id) ? _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Amount")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" " + _vm._s(_vm.orderdetail.currency_code) + " " + _vm._s(_vm.formatPrice(_vm.orderdetail.amount, _vm.orderdetail.currency_code)) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "w-1/2"
  }, [_c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Payment Status")]), (_vm$orderdetail13 = _vm.orderdetail) !== null && _vm$orderdetail13 !== void 0 && _vm$orderdetail13.payment_status ? _c('div', [_c('paymentStatusVue', {
    attrs: {
      "payment": parseInt((_vm$orderdetail14 = _vm.orderdetail) === null || _vm$orderdetail14 === void 0 ? void 0 : _vm$orderdetail14.payment_status, 10)
    }
  })], 1) : _c('div', [_vm._v(" - ")])]), [24].includes((_vm$orderdetail15 = _vm.orderdetail) === null || _vm$orderdetail15 === void 0 ? void 0 : _vm$orderdetail15.paymentgateway_id) ? _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Status Active")]), _c('div', [_vm.orderdetail.is_active ? _c('div', [_c('span', {
    staticClass: "bg-blue-100 text-blue-800 px-2 py-1 rounded-lg text-sm"
  }, [_vm._v("active")])]) : _c('div', [_c('span', {
    staticClass: "bg-red-100 text-red-800 px-2 py-1 rounded-lg text-sm"
  }, [_vm._v(" inactive")])])])]) : _vm._e(), [24].includes((_vm$orderdetail16 = _vm.orderdetail) === null || _vm$orderdetail16 === void 0 ? void 0 : _vm$orderdetail16.paymentgateway_id) ? _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Trial Test")]), _c('div', [_vm.orderdetail.is_trial ? _c('div', [_c('span', {
    staticClass: "bg-blue-100 text-blue-800 px-2 py-1 rounded-lg text-sm"
  }, [_vm._v("True")])]) : _c('div', [_c('span', {
    staticClass: "bg-red-100 text-red-800 px-2 py-1 rounded-lg text-sm"
  }, [_vm._v(" False")])])])]) : _vm._e(), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Payment Date")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" " + _vm._s((_vm$orderdetail17 = _vm.orderdetail) !== null && _vm$orderdetail17 !== void 0 && _vm$orderdetail17.payment_datetime ? _vm.formatDate((_vm$orderdetail18 = _vm.orderdetail) === null || _vm$orderdetail18 === void 0 ? void 0 : _vm$orderdetail18.payment_datetime) : '-') + " ")])]), [24].includes((_vm$orderdetail19 = _vm.orderdetail) === null || _vm$orderdetail19 === void 0 ? void 0 : _vm$orderdetail19.paymentgateway_id) ? _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Created Date")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" " + _vm._s((_vm$orderdetail20 = _vm.orderdetail) !== null && _vm$orderdetail20 !== void 0 && _vm$orderdetail20.created ? _vm.formatDate((_vm$orderdetail21 = _vm.orderdetail) === null || _vm$orderdetail21 === void 0 ? void 0 : _vm$orderdetail21.created) : '-') + " ")])]) : _vm._e()])]), _c('t-modal', {
    ref: "confirmationModal"
  }, [_c('div', {
    staticClass: "text-sm flex-col text-center"
  }, [_c('label', {
    staticClass: "text-center font-semibold"
  }, [_vm._v("Are You Sure?")]), _c('p', {
    staticClass: "text-left text-gray-500"
  }, [_vm._v("You will update payment type to," + _vm._s(this.paymentType))])]), _c('div', {
    staticClass: "flex justify-center"
  }, [_c('button', {
    staticClass: "bg-blue-500 text-white p-1 px-2 rounded-lg mr-1 text-sm"
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "bg-white border text-gray-500 p-1 px-2 rounded-lg ml-1 text-sm",
    on: {
      "click": function ($event) {
        var _vm$orderdetail22, _vm$orderdetail23;
        return _vm.handleSave((_vm$orderdetail22 = _vm.orderdetail) === null || _vm$orderdetail22 === void 0 ? void 0 : _vm$orderdetail22.is_test_payment, (_vm$orderdetail23 = _vm.orderdetail) === null || _vm$orderdetail23 === void 0 ? void 0 : _vm$orderdetail23.id);
      }
    }
  }, [_vm._v("Yes")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }