var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-black"
  }, _vm._l(_vm.filteredStatus, function (status) {
    return _c('span', {
      key: status.id,
      staticClass: "capitalize",
      class: ("" + (status.class))
    }, [_vm._v(" " + _vm._s(status.text) + " ")]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }