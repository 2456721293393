<template>
  <div>
    <div class="relative overflow-x-auto">
        <div class="text-sm flex-col text-center">
          <table class="table-auto w-full text-sm text-left text-gray-500 dark:text-white">
    <thead class="text-xs uppercase bg-gray-200 dark:bg-gray-800 dark:text-gray-400">
      <tr>
        <th scope="col" class="px-2 py-3">No</th>
        <th scope="col" class="px-2 py-3">Created</th>
        <th scope="col" class="px-2 py-3">Status</th>
        <th scope="col" class="px-2 py-3">Detail</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(orderpaymentcc, index) in orderdetail.paymentcc"
        :key="orderpaymentcc.id"
        class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
      >
        <td class="py-2 px-2 text-gray-500">
          {{ index + 1 }}
        </td>
        <td class="py-2 px-2 text-gray-500">
          {{ formatDate(orderpaymentcc.created) }}
        </td>
        <td class="py-2 px-2 text-gray-500">
          {{ orderpaymentcc?.status ? orderpaymentcc?.status : '-' }}
        </td>
        <td class="py-2 px-2 text-gray-500">
          <button
            @click="toggleLog(orderpaymentcc)"
            class="px-2 py-1 rounded-lg bg-blue-500 text-white"
          >
            {{ selectedLog === orderpaymentcc ? 'Hide' : 'Show' }}
          </button>
        </td>
      </tr>
      <tr v-if="selectedLog">
        <td colspan="4" class="p-0">
          <div class="bg-gray-100 p-2 rounded dark:bg-gray-700">
            <h4 class="font-semibold mb-2 text-gray-700 dark:text-gray-300">
              Data Request
            </h4>
            <pre class="overflow-auto text-xs bg-white p-2 rounded dark:bg-gray-900 dark:text-gray-400">
{{ JSON.stringify(selectedLog.data_request, null, 2) }}
            </pre>
            <h4 class="font-semibold mt-4 mb-2 text-gray-700 dark:text-gray-300">
              Data Response
            </h4>
            <pre class="overflow-auto text-xs bg-white p-2 rounded dark:bg-gray-900 dark:text-gray-400">
{{ JSON.stringify(selectedLog.data_response, null, 2) }}
            </pre>
          </div>
        </td>
      </tr>
    </tbody>
  </table>


        </div>

      </div>
    <!-- <b-table
      id="table-transition"
      show-empty
      :fields="header"
      hover
      responsive="xl"
      :items="orderdetail.paymentcc"
      sort-icon-left
    >
      <template #cell(No)="row"> {{ row.index + 1 }}. </template>
      <template #cell(created)="row">
        {{ row.item?.created ? formatDate(row.item?.created) : '-' }}
      </template>
      <template #cell(status)="row">
        {{ row.item?.status ? row.item?.status : '-' }}
      </template>
      <template #cell(detail)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2 btn-success">
          {{ row.detailsShowing ? 'Hide' : 'Detail' }}
        </b-button>
      </template>
      <template #row-details="row">
        <div class="row">
          <div class="col-sm-6">
            <span class="font-weight-bold">Data Request</span>
            <pre>{{ JSON.stringify(row.item.data_request, null, 2) }}</pre>
          </div>
          <div class="col-sm-6">
            <span class="font-weight-bold">Data Response</span>
            <pre>{{ JSON.stringify(row.item.data_response, null, 2) }}</pre>
          </div>
        </div>
      </template>
    </b-table> -->
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DetailCC',
  props: {
    orderdetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedLog: null,
    };
  },
  methods: {
    formatDate(tgl) {
      return moment(tgl).format('DD MMMM YYYY HH:mm:ss');
    },
    toggleLog(orderpaymentcc) {
      // Jika data yang sama diklik, hapus seleksi
      this.selectedLog =
        this.selectedLog === orderpaymentcc ? null : orderpaymentcc;
    },
  },
};
</script>
