<template>
  <div>
    <div class="relative overflow-x-auto">
        <div class="text-sm flex-col text-center">
          <table class="table-auto w-full text-sm text-left text-gray-500 dark:text-white">
    <thead class="text-xs uppercase bg-gray-200 dark:bg-gray-800 dark:text-gray-400">
      <tr>
        <th scope="col" class="px-2 py-3">No</th>
        <th scope="col" class="px-2 py-3">Created date</th>
        <th scope="col" class="px-2 py-3">Detail</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(orderpayment, index) in orderdetail.paymentgopay"
        :key="orderpayment.id"
        class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
      >
        <td class="py-2 px-2 text-gray-500">
          {{ index + 1 }}
        </td>
        <td class="py-2 px-2 text-gray-500">
          {{ formatDate(orderpayment.created) }}
        </td>
        <td class="py-2 px-2 text-gray-500">
          <button
            @click="toggleLog(orderpayment)"
            class="px-2 py-1 rounded-lg bg-blue-500 text-white"
          >
            {{ selectedLog === orderpayment ? 'Hide' : 'Show' }}
          </button>
        </td>
      </tr>
      <tr v-if="selectedLog">
        <td colspan="4" class="p-0">
          <div class="bg-gray-100 p-2 rounded dark:bg-gray-700">
            <h4 class="font-semibold mb-2 text-gray-700 dark:text-gray-300">
              Data Request
            </h4>
            <pre class="overflow-auto text-xs bg-white p-2 rounded dark:bg-gray-900 dark:text-gray-400">
              {{ JSON.stringify(selectedLog.data_request, null, 2) }}
            </pre>
            <h4 class="font-semibold mt-4 mb-2 text-gray-700 dark:text-gray-300">
              Data Response
            </h4>
            <pre class="overflow-auto text-xs bg-white p-2 rounded dark:bg-gray-900 dark:text-gray-400">
              {{ JSON.stringify(selectedLog.data_request, null, 2) }}
            </pre>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
        </div>
      </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DetailGopay',
  props: {
    orderdetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      header: [
        { key: 'No', label: 'No' },
        { key: 'created', label: 'Created' },
        { key: 'status', label: 'Status' },
        { key: 'detail', label: 'Detail' },
      ],
      selectedLog:null
    };
  },
  methods: {
    formatDate(tgl) {
      return moment(tgl).format('DD MMMM YYYY HH:mm:ss');
    },
    toggleLog(orderpayment) {
      console.log('aa',orderpayment)
      this.selectedLog =
        this.selectedLog === orderpayment ? null : orderpayment;
    },
  },
};
</script>
