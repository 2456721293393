var render = function () {
  var _vm$item, _vm$item$users, _vm$item$currency_cod, _vm$item$currency_cod2, _vm$item$currency_cod3, _vm$item$store_code, _vm$item$payment;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "bg-white p-4 my-4 rounded-lg shadow-sm"
  }, [_c('label', [_c('span', {
    staticClass: "text-capitalize cursor-pointer text-gray-900 font-semibold",
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v(" Discount")]), _vm._v(" "), _c('i', {
    staticClass: "fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"
  }), _vm._v(" "), _c('span', {
    staticClass: "text-gray-500 font-normal capitalize"
  }, [_vm._v(" " + _vm._s(_vm.item.id))])]), _c('h4', {
    staticClass: "text-xl capitalize"
  }, [_vm._v(" " + _vm._s(_vm.item.id))])]), _c('div', {
    staticClass: "bg-white p-4 my-4 rounded-lg shadow-sm"
  }, [_c('label', {
    staticClass: "text-base text-gray-900 font-bold w-full block"
  }, [_vm._v("Order Details")]), _c('div', {
    staticClass: "flex justify-start"
  }, [_c('div', {
    staticClass: "w-1/2 mt-2"
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Order ID")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(_vm._s(_vm.item.id))])]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Email")]), _c('router-link', {
    staticClass: "text-gray-500 no-underline hover:no-underline hover:bg-transparent",
    attrs: {
      "to": {
        path: '/customers/detail/' + ((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : (_vm$item$users = _vm$item.users) === null || _vm$item$users === void 0 ? void 0 : _vm$item$users.email)
      },
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.users ? _vm.item.users.email : '-') + " ")])], 1), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Order Number")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(_vm._s(_vm.item.order_number))])]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Renewal Type")]), _vm.item.is_renewal ? _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" Renewal ")]) : _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" Non-Renewal ")])]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Total Amount")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" " + _vm._s((_vm$item$currency_cod = _vm.item.currency_code) === null || _vm$item$currency_cod === void 0 ? void 0 : _vm$item$currency_cod.toUpperCase()) + " " + _vm._s(_vm.formatPrice(_vm.item.total_amount, _vm.item.currency_code)) + " ")])]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Discount")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" " + _vm._s((_vm$item$currency_cod2 = _vm.item.currency_code) === null || _vm$item$currency_cod2 === void 0 ? void 0 : _vm$item$currency_cod2.toUpperCase()) + " " + _vm._s(_vm.getDiscountPrice(_vm.item.total_amount, _vm.item.final_amount, _vm.item.currency_code)) + " ")])]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Final Amount")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" " + _vm._s((_vm$item$currency_cod3 = _vm.item.currency_code) === null || _vm$item$currency_cod3 === void 0 ? void 0 : _vm$item$currency_cod3.toUpperCase()) + " " + _vm._s(_vm.formatPrice(_vm.item.final_amount, _vm.item.currency_code)) + " ")])]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Store Referral Code")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(_vm._s(((_vm$item$store_code = _vm.item.store_code) === null || _vm$item$store_code === void 0 ? void 0 : _vm$item$store_code.store) || '-'))])]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Payment Type")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" " + _vm._s(((_vm$item$payment = _vm.item.payment) === null || _vm$item$payment === void 0 ? void 0 : _vm$item$payment.name) || '-'))])]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Platform Type")]), _c('div', {
    staticClass: "text-gray-500 flex justify-start"
  }, [_c('Platform', {
    attrs: {
      "platform": _vm.item.platform_id
    }
  })], 1)]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Client ID")]), _c('span', {
    staticClass: "text-gray-500 normal-case"
  }, [_vm._v(" " + _vm._s(_vm.clientID(_vm.item.client_id)))])]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Tier Code")]), _c('span', {
    staticClass: "text-gray-500 normal-case"
  }, [_vm._v(" " + _vm._s(_vm.item.tier_code ? _vm.item.tier_code : '-'))])])]), _c('div', {
    staticClass: "w-1/2"
  }, [_c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Status")]), _c('div', [_vm.item.is_active ? _c('div', [_c('span', {
    staticClass: "bg-blue-100 text-blue-800 px-2 py-1 rounded-lg text-sm"
  }, [_vm._v("active")])]) : _c('div', [_c('span', {
    staticClass: "bg-red-100 text-red-800 px-2 py-1"
  }, [_vm._v(" inactive")])])])]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Order Status")]), _c('div', {
    staticClass: "text-gray-500 flex justify-start"
  }, [_c('paymentview', {
    attrs: {
      "payment": _vm.item.order_status
    }
  })], 1)]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Transaction Time")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.item.created)) + " ")])]), _c('div', {
    staticClass: "flex flex-col mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-sm mb-1"
  }, [_vm._v("Last Modified Date")]), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.item.modified)) + " ")])])])])]), _c('div', {
    staticClass: "bg-white p-4 my-4 rounded-lg shadow-sm"
  }, [_c('label', {
    staticClass: "font-bold text-base"
  }, [_vm._v("All Contents Under this Section Title")]), _c('div', {
    staticClass: "relative overflow-x-auto"
  }, [_c('div', {
    staticClass: "text-sm flex-col text-center"
  }, [_c('table', {
    staticClass: "table-auto w-full text-sm text-left text-gray-500 dark:text-white"
  }, [_c('thead', {
    staticClass: "text-xs uppercase bg-gray-200 dark:bg-gray-800 dark:text-gray-400"
  }, [_c('tr', [_c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Orderline key")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Item name")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("brand name")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Qty")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Price")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Discount")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Final price")])])]), _c('tbody', _vm._l(_vm.item.orderlines, function (orderline) {
    var _vm$item2, _vm$item3, _vm$item4, _vm$item5;
    return _c('tr', {
      key: orderline.id,
      staticClass: "bg-white border-b dark:bg-gray-900 dark:border-gray-700"
    }, [_c('td', {
      staticClass: "px-2 py-4 font-medium text-gray-900 dark:text-white"
    }, [_vm._v(" " + _vm._s(orderline.id) + " ")]), _c('td', {
      staticClass: "px-2 py-4"
    }, [_vm._v(" " + _vm._s(orderline.name) + " ")]), _c('td', {
      staticClass: "px-2 py-4"
    }, [_vm._v(" " + _vm._s(orderline.brand_name) + " ")]), _c('td', {
      staticClass: "px-2 py-4"
    }, [_vm._v(" " + _vm._s(orderline.quantity) + " ")]), _c('td', {
      staticClass: "px-2 py-4"
    }, [_vm._v(" " + _vm._s((_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.currency_code) + " " + _vm._s(_vm.formatPrice(orderline.price, orderline.currency_code)) + " ")]), _c('td', {
      staticClass: "px-2 py-4"
    }, [_vm._v(" " + _vm._s((_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.currency_code) + " " + _vm._s(_vm.getDiscountPrice(orderline.price, orderline.final_price, (_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.currency_code, orderline.currency_code, orderline.quantity)) + " ")]), _c('td', {
      staticClass: "px-2 py-4"
    }, [_vm._v(" " + _vm._s((_vm$item5 = _vm.item) === null || _vm$item5 === void 0 ? void 0 : _vm$item5.currency_code) + " " + _vm._s(_vm.formatPrice(orderline.final_price, orderline.currency_code)) + " ")])]);
  }), 0), _vm.item.orderlines.length === 0 ? _c('tbody', [_c('td', {
    attrs: {
      "colspan": "15"
    }
  }, [_c('p', {
    staticClass: "text-sm text-gray-500 text-center mt-2"
  }, [_vm._v("There are no records to show")])])]) : _vm._e()])])])]), _c('div', {
    staticClass: "bg-white p-4 my-4 rounded-lg shadow-sm"
  }, [_c('label', {
    staticClass: "font-bold text-base"
  }, [_vm._v("Payment Details")]), _c('div', {
    staticClass: "flex items-start justify-between mb-4"
  }, [_c('div', {
    staticClass: "w-8/12"
  }, [_vm.detailPayment ? _c('div', [_c('detailPaymentVue', {
    attrs: {
      "orderdetail": _vm.detailPayment,
      "updatePaymentType": _vm.onUpdatePaymentType
    }
  })], 1) : _vm._e()]), _c('div', [_vm.item.platform_id === 1 ? _c('div', {
    staticClass: "mb-3"
  }, [[20001, 50004, 20002, 10001].includes(_vm.item.order_status) ? _c('button', {
    staticClass: "bg-blue-500 text-white rounded-lg px-2 py-2",
    on: {
      "click": _vm.popupAppleID
    }
  }, [_vm._v(" Insert Apple Order ID ")]) : _vm._e()]) : _vm._e()])]), _c('div', {
    staticClass: "relative overflow-x-auto"
  }, [_c('div', [this.item.paymentgateway_id == _vm.applePay && _vm.detailPayment.paymentappleiaps ? _c('div', [_c('Itunes', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), this.item.paymentgateway_id == _vm.googlePay && _vm.detailPayment.paymentinappbillings ? _c('div', [_c('Google', {
    attrs: {
      "orderdetail": _vm.detailPayment,
      "submitToken": _vm.onSubmitToken,
      "submitGPA": _vm.onSubmitGPA
    }
  })], 1) : _vm._e(), this.item.paymentgateway_id == _vm.linkAjaPay && (_vm.detailPayment.paymentva || _vm.detailPayment.paymentdetail) ? _c('div', [_c('LinkAja', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), _vm.paymentVA.includes(parseInt(this.item.paymentgateway_id)) && (_vm.detailPayment.paymentva || _vm.detailPayment.paymentdetail) ? _c('div', [_c('VirtualAccount', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), this.item.paymentgateway_id == _vm.gopay && _vm.detailPayment.paymentgopay ? _c('div', [_c('GoPay', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), this.item.paymentgateway_id == _vm.cc && _vm.detailPayment.paymentcc ? _c('div', [_c('CreditCard', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), this.item.paymentgateway_id == _vm.free && _vm.detailPayment.orderlinediscount ? _c('div', [_c('FreePurchase', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e(), _vm.eTwoPayCIMB.includes(parseInt(this.item.paymentgateway_id)) && _vm.detailPayment.paymentdetail ? _c('div', [_c('eTwoPayGroup', {
    attrs: {
      "orderdetail": _vm.detailPayment
    }
  })], 1) : _vm._e()])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }