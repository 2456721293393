var render = function () {
  var _vm$orderdetail;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "table-responsive"
  }, [_c('div', {
    staticClass: "relative overflow-x-auto"
  }, [_c('div', {
    staticClass: "text-sm flex-col text-center"
  }, [_c('table', {
    staticClass: "table-auto w-full text-sm text-left text-gray-500 dark:text-white"
  }, [_vm._m(0), _c('tbody', _vm._l((_vm$orderdetail = _vm.orderdetail) === null || _vm$orderdetail === void 0 ? void 0 : _vm$orderdetail.paymentappleiaps, function (orderAppleAps, index) {
    return _c('tr', {
      key: orderAppleAps === null || orderAppleAps === void 0 ? void 0 : orderAppleAps.id,
      staticClass: "bg-white border-b dark:bg-gray-900 dark:border-gray-700"
    }, [_c('td', {
      staticClass: "py-2 px-2 text-gray-500"
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', {
      staticClass: "py-2 px-2 text-gray-500"
    }, [_c('span', [_vm._v(_vm._s(_vm.formatDate(orderAppleAps === null || orderAppleAps === void 0 ? void 0 : orderAppleAps.purchase_date).date))]), _c('br'), _c('span', {
      staticClass: "text-xs"
    }, [_vm._v(_vm._s(_vm.formatDate(orderAppleAps === null || orderAppleAps === void 0 ? void 0 : orderAppleAps.purchase_date).time))])]), _c('td', {
      staticClass: "py-2 px-2 text-gray-500"
    }, [_vm._v(" " + _vm._s((orderAppleAps === null || orderAppleAps === void 0 ? void 0 : orderAppleAps.payment_id) || '-') + " ")]), _c('td', {
      staticClass: "py-2 px-2 text-gray-500"
    }, [_vm._v(" " + _vm._s((orderAppleAps === null || orderAppleAps === void 0 ? void 0 : orderAppleAps.product_id) || '-') + " ")]), _c('td', {
      staticClass: "py-2 px-2 text-gray-500"
    }, [_vm._v(" " + _vm._s((orderAppleAps === null || orderAppleAps === void 0 ? void 0 : orderAppleAps.original_transaction_id) || '-') + " ")]), _c('td', {
      staticClass: "py-2 px-2 text-gray-500"
    }, [_vm._v(" " + _vm._s((orderAppleAps === null || orderAppleAps === void 0 ? void 0 : orderAppleAps.transaction_id) || '-') + " ")]), _c('td', {
      staticClass: "py-2 px-2 text-gray-500"
    }, [_vm._v(" " + _vm._s((orderAppleAps === null || orderAppleAps === void 0 ? void 0 : orderAppleAps.receipt_version) || '-') + " ")])]);
  }), 0), _vm.orderdetail.paymentappleiaps.length === 0 ? _c('tbody', [_vm._m(1)]) : _vm._e()])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "text-xs uppercase bg-gray-200 dark:bg-gray-800 dark:text-gray-400"
  }, [_c('tr', [_c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Purchase Date")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Payment ID")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Product ID")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Original Trx")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Transaction ID")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Receipt Version")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    attrs: {
      "colspan": "15"
    }
  }, [_c('p', {
    staticClass: "text-sm text-gray-500 text-center mt-2"
  }, [_vm._v("There are no records to show")])]);
}]

export { render, staticRenderFns }