var render = function () {
  var _vm$orderdetail;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "relative overflow-x-auto"
  }, [_c('div', {
    staticClass: "text-sm flex-col text-center"
  }, [_c('table', {
    staticClass: "table-auto w-full text-sm text-left text-gray-500 dark:text-white"
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.orderdetail.paymentinappbillings, function (orderpayment, index) {
    return _c('tr', {
      key: orderpayment.id,
      staticClass: "bg-white border-b dark:bg-gray-900 dark:border-gray-700"
    }, [_c('td', {
      staticClass: "py-2 px-2 text-gray-500"
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', {
      staticClass: "py-2 px-2 text-gray-500"
    }, [_vm._v(" " + _vm._s(_vm.formatDate(orderpayment.created)) + " ")]), _c('td', {
      staticClass: "py-2 px-2 text-gray-500"
    }, [_vm._v(" " + _vm._s(orderpayment.status) + " ")]), _c('td', {
      staticClass: "py-2 px-2 text-gray-500 w-44"
    }, [_vm._v(" " + _vm._s(orderpayment !== null && orderpayment !== void 0 && orderpayment.purchase_token ? orderpayment === null || orderpayment === void 0 ? void 0 : orderpayment.purchase_token : 'PurchaseToken Notfound') + " "), orderpayment !== null && orderpayment !== void 0 && orderpayment.purchase_token ? _c('button', {
      attrs: {
        "size": "sm",
        "variant": "outline"
      },
      on: {
        "click": function ($event) {
          return _vm.copyTextToClipboard(orderpayment.purchase_token);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-copy"
    })]) : _vm._e(), !_vm.showButtonInsertToken ? _c('b-button', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.modal-insert-purchase-token-g-payment",
        modifiers: {
          "modal-insert-purchase-token-g-payment": true
        }
      }],
      staticClass: "ml-2",
      attrs: {
        "size": "sm",
        "variant": "primary"
      }
    }, [_vm._v(" Insert Token ")]) : _vm._e()], 1), _c('td', {
      staticClass: "py-2 px-2 text-gray-500"
    }, [_vm._v(" " + _vm._s(orderpayment !== null && orderpayment !== void 0 && orderpayment.iab_order_id ? orderpayment === null || orderpayment === void 0 ? void 0 : orderpayment.iab_order_id : 'GPA Order Not Found') + " "), !_vm.showButtonInsertGPA ? _c('b-button', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.modal-insert-gpa",
        modifiers: {
          "modal-insert-gpa": true
        }
      }],
      staticClass: "ml-2",
      attrs: {
        "size": "sm",
        "variant": "primary"
      }
    }, [_vm._v(" Insert GPA ")]) : _vm._e()], 1)]);
  }), 0), _vm.orderdetail.paymentinappbillings.length === 0 ? _c('tbody', [_vm._m(1)]) : _vm._e()])])]), _c('b-modal', {
    attrs: {
      "id": "modal-insert-purchase-token-g-payment",
      "title": 'Insert Token for order : ' + _vm.orderdetail.order_id,
      "ok-visible": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "form": "form-insert-token-g-payment",
            "type": "submit"
          }
        }, [_vm._v("Save")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form', {
    attrs: {
      "id": "form-insert-token-g-payment"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmitToken.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', [_c('b-form-input', {
    attrs: {
      "id": "token-g-payment",
      "placeholder": "put token here..",
      "required": ""
    },
    model: {
      value: _vm.purchase_token,
      callback: function ($$v) {
        _vm.purchase_token = $$v;
      },
      expression: "purchase_token"
    }
  }), _c('br')], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-insert-gpa",
      "title": 'Insert GPA for order : ' + ((_vm$orderdetail = _vm.orderdetail) === null || _vm$orderdetail === void 0 ? void 0 : _vm$orderdetail.order_id),
      "ok-visible": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "form": "form-insert-gpa",
            "type": "submit"
          }
        }, [_vm._v("Save")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form', {
    attrs: {
      "id": "form-insert-gpa"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmitGPA.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "GPA.3328-1533-0108-86973",
      "required": ""
    },
    model: {
      value: _vm.google_iab,
      callback: function ($$v) {
        _vm.google_iab = $$v;
      },
      expression: "google_iab"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "text-xs uppercase bg-gray-200 dark:bg-gray-800 dark:text-gray-400"
  }, [_c('tr', [_c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Payment Time")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Status")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("Purchase Token")]), _c('th', {
    staticClass: "px-2 py-3",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("IAB Order ID")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    attrs: {
      "colspan": "15"
    }
  }, [_c('p', {
    staticClass: "text-sm text-gray-500 text-center mt-2"
  }, [_vm._v("There are no records to show")])]);
}]

export { render, staticRenderFns }