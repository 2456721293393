<template>
 <div class="text-center">
    <i
      v-if="platform in platformIcons"
      :class="platformIcons[platform]"
      aria-hidden="true"
    ></i>
  </div>
</template>

<script>
export default {
  name: "platform",

  props: {
    platform: {
      value: [String,Number,Boolean],
      required: true
    }
  },

  data() {
    return {
      platformIcons: {
        1: "fab fa-apple",
        2: "fab fa-android",
        3: "fab fa-windows", 
        4: "fab fa-firefox-browser",
      },
    };
  },
};
</script>
