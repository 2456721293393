<template>
  <div class="w-full">
    <div class="flex justify-between">
      <div class="w-1/2">
        <div class="flex flex-col mt-3">
          <label class="font-semibold text-sm mb-1">Payment ID</label>
          <span class="text-gray-500">
            {{ orderdetail?.id ? orderdetail?.id : '-' }}
          </span>
        </div>
        <div class="flex flex-col mt-3">
          <label class="font-semibold text-sm mb-1">Payment Type</label>
          <div v-if="Object.keys(orderdetail)?.length">

          <span style="cursor: pointer" class="text-gray-500 text-base bg-gray-100 rounded-lg" data-toggle="tooltip" data-placement="top"
            title="Update Payment Type To Production" v-if="orderdetail?.is_test_payment" @click="
              editPaymentType(orderdetail?.is_test_payment, orderdetail?.id)
              ">TESTING</span>
          <span style="cursor: pointer" class="text-white bg-blue-500 text-base px-2 py-1 rounded-lg" data-toggle="tooltip" data-placement="top"
            title="Update Payment Type To Testing" v-if="!orderdetail?.is_test_payment" @click="
              editPaymentType(orderdetail?.is_test_payment, orderdetail?.id)
              ">Production</span>
        </div>
        <div v-else>
          -
        </div>
      </div>
      <div class="flex flex-col mt-3" v-if="[24].includes(orderdetail?.paymentgateway_id)">
          <label class="font-semibold text-sm mb-1">Currency</label>
          <span class="text-gray-500">
            {{ orderdetail?.currency_code ? orderdetail?.currency_code : '-' }}
          </span>
        </div> 
        <div class="flex flex-col mt-3" v-if="[24].includes(orderdetail?.paymentgateway_id)">
          <label class="font-semibold text-sm mb-1">Amount</label>
          <span class="text-gray-500">
            {{ orderdetail.currency_code }}
            {{formatPrice(orderdetail.amount, orderdetail.currency_code)}}
          </span>
        </div> 
      </div>
      <div class="w-1/2">
        <div class="flex flex-col mt-3">
          <label class="font-semibold text-sm mb-1">Payment Status</label>
          <div v-if="orderdetail?.payment_status">
            <paymentStatusVue :payment="parseInt(orderdetail?.payment_status, 10)" />
          </div>
          <div v-else>
            -
          </div>
        </div>
        <div class="flex flex-col mt-3" v-if="[24].includes(orderdetail?.paymentgateway_id)">
          <label class="font-semibold text-sm mb-1">Status Active</label>
          <div>
              <div v-if="orderdetail.is_active">
                <span class="bg-blue-100 text-blue-800 px-2 py-1 rounded-lg text-sm">active</span>
              </div>
              <div v-else>
                <span class="bg-red-100 text-red-800 px-2 py-1 rounded-lg text-sm">
                  inactive</span>
              </div>
            </div>
        </div>
        <div class="flex flex-col mt-3" v-if="[24].includes(orderdetail?.paymentgateway_id)">
          <label class="font-semibold text-sm mb-1">Trial Test</label>
          <div>
              <div v-if="orderdetail.is_trial">
                <span class="bg-blue-100 text-blue-800 px-2 py-1 rounded-lg text-sm">True</span>
              </div>
              <div v-else>
                <span class="bg-red-100 text-red-800 px-2 py-1 rounded-lg text-sm">
                  False</span>
              </div>
            </div>
        </div>
        <div class="flex flex-col mt-3">
          <label class="font-semibold text-sm mb-1">Payment Date</label>
          <span class="text-gray-500">
            {{
              orderdetail?.payment_datetime
                ? formatDate(orderdetail?.payment_datetime)
                : '-'
            }}
          </span>
        </div> 
        <div class="flex flex-col mt-3" v-if="[24].includes(orderdetail?.paymentgateway_id)">
          <label class="font-semibold text-sm mb-1">Created Date</label>
          <span class="text-gray-500">
            {{
              orderdetail?.created
                ? formatDate(orderdetail?.created)
                : '-'
            }}
          </span>
        </div> 
      </div>
    </div>
    <t-modal ref="confirmationModal">
      <div class="text-sm flex-col text-center">
        <label class="text-center font-semibold">Are You Sure?</label>
        <p class="text-left text-gray-500">You will update payment type to,{{this.paymentType}}</p>
      </div>
      <div class="flex justify-center">
        <button class="bg-blue-500 text-white p-1 px-2 rounded-lg mr-1 text-sm" >Cancel</button>
        <button class="bg-white border text-gray-500 p-1 px-2 rounded-lg ml-1 text-sm"
        @click="handleSave(orderdetail?.is_test_payment, orderdetail?.id)">Yes</button>
      </div>
    </t-modal>

  </div>
</template>
<script>
import paymentStatusVue from './paymentStatus.vue';
import moment from 'moment';
export default {
  name: 'DetailPayment',
  components: {
    paymentStatusVue,
  },
  data() {
    return {
      paymentType:null
    };
  },
  props: {
    orderdetail: {
      type: Object,
      required: true,
    },
    updatePaymentType: {
      type: Function,
      required: true,
    },
  },
  methods: {
    formatDate(tgl) {
      return moment(tgl).format('DD MMMM YYYY HH:mm:ss');
    },
    formatPrice(value, currencyCode) {
      if (currencyCode?.toLowerCase() == 'idr') {
        let val = (value / 1).toFixed(0).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return value;
    },
   
    editPaymentType(type, id) {
      console.log('id type',id)
      this.$refs.confirmationModal.show();
       this.paymentType = type ? 'PRODUCTION' : 'TESTING'
    },
    handleSave(type,id) {
      this.updatePaymentType(type, id);
      this.$refs.confirmationModal.hide();
    }
  },
};
</script>
